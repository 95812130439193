import styled from 'styled-components'
import PropTypes from 'prop-types'
import { up } from 'styled-breakpoints'

export default function Container({ children }) {
  return <StyledContainer>{children}</StyledContainer>
}

Container.defaultProps = {}

Container.propTypes = {
  children: PropTypes.node.isRequired,
}

export const StyledContainer = styled.div`
  padding-left: ${(props) => props.theme.containerGutters};
  padding-right: ${(props) => props.theme.containerGutters};
  max-width: ${(props) => props.theme.containerMaxWidth};
  margin-left: auto;
  margin-right: auto;

  ${up('md')} {
    padding-left: ${(props) => props.theme.containerGuttersMd};
    padding-right: ${(props) => props.theme.containerGuttersMd};
  }
`
